/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */


import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import { Icon, Modal } from "antd"

//GPDR
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {

  const [showMenu, setShowMenu] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //Modal
  const [showModalContact, setShowModalContact] = useState(false)

  const showModalContactForm = () => {
    setShowModalContact(true)
  }

  const handleContactFormOk = e => {
    setShowModalContact(false)
  }

  const handleContactFormCancel = e => {
    setShowModalContact(false)
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <div>
        <main>{children}</main>
        <footer>
          <CookieConsent
            style={{ background: "#2c2f4c", color: '#fff', fontSize: "13px", zIndex: '99999999' }}
            buttonStyle={{ background: "#796b4b", color: "#fff", fontSize: "13px" }}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
          <div className="sct-footer">
            <div style={{position: 'absolute', right: '20px'}}>
              <span style={{marginRight: '20px'}} onClick={ () => showModalContactForm()}><a href="#">RGPD</a></span>
              © {new Date().getFullYear()}
              {` `}
              <a href="https://www.nammu.ch" title="Agence Web Genève">nammu.ch</a>
            </div>
          </div>
        </footer>
        <div className="mobileMenu">
          <div className="border-gold" style={{height: '7px'}}></div>
          <div onClick={() => setShowMenu(!showMenu)} className="bt-menu-mobile"><div className="bt-menu-mobile-inset"></div></div>
        </div>
        { showMenu
          ? <div className="mobile-menu-open">
            <div className="mobile-menu-content">
              <ul>
                <li className="menu-txt"><Link className="mobile-link" to="/" onClick={() => setShowMenu(!showMenu)}>Accueil</Link></li>
                <li className="menu-txt"><Link className="mobile-link" to="/#presentation" onClick={() => setShowMenu(!showMenu)}>Histoire</Link></li>
                <li className="menu-txt"><Link className="mobile-link" to="/bijoux" onClick={() => setShowMenu(!showMenu)}>Bijouterie <Icon type="down" /></Link></li>
                <ul style={{fontSize: '0.8em'}}>
                  <li className="menu-txt"><Link className="mobile-link" to="/savoirfaire" onClick={() => setShowMenu(!showMenu)}>Notre savoir-faire</Link></li>
                  <li className="menu-txt"><Link className="mobile-link" to="/bagues" onClick={() => setShowMenu(!showMenu)}>Bagues</Link></li>
                  <li className="menu-txt"><Link className="mobile-link" to="/alliances" onClick={() => setShowMenu(!showMenu)}>Alliances</Link></li>
                  <li className="menu-txt"><Link className="mobile-link" to="/solitaires" onClick={() => setShowMenu(!showMenu)}>Solitaires</Link></li>
                  <li className="menu-txt"><Link className="mobile-link" to="/colliers_pendentifs" onClick={() => setShowMenu(!showMenu)}>Colliers & Pendentifs</Link></li>
                  <li className="menu-txt"><Link className="mobile-link" to="/boucles_d_oreilles" onClick={() => setShowMenu(!showMenu)}>Boucles d'oreilles</Link></li>
                  <li className="menu-txt"><Link className="mobile-link" to="/perles_pierres" onClick={() => setShowMenu(!showMenu)}>Perles & Pierres</Link></li>
                </ul>
                <li className="menu-txt"><Link className="mobile-link" to="/horlogerie" onClick={() => setShowMenu(!showMenu)}>Horlogerie <Icon type="down" /></Link></li>
                <ul style={{fontSize: '0.8em'}}>
                  <li className="menu-txt"><Link className="mobile-link" to="/montres-neuves-et-d-occasion" onClick={() => setShowMenu(!showMenu)}>Montres neuves et d'occasion</Link></li>
                  <li className="menu-txt"><Link className="mobile-link" to="/savoirfaireHorlogeries" onClick={() => setShowMenu(!showMenu)}>Notre savoir-faire</Link></li>

                  <li className="menu-txt"><Link to="/horlogerie_yvan_monnet" className="mobile-link" onClick={() => setShowMenu(!showMenu)}>Yvan Monnet</Link></li>
                  <li className="menu-txt"><Link to="/horlogerie_grovana" className="mobile-link" onClick={() => setShowMenu(!showMenu)}>Grovana</Link></li>
                  <li className="menu-txt"><Link to="/horlogerie_maserati" className="mobile-link" onClick={() => setShowMenu(!showMenu)}>Maserati</Link></li>
                  <li className="menu-txt"><Link to="/horlogerie_gvchiani" className="mobile-link" onClick={() => setShowMenu(!showMenu)}>Gvchiani Genève</Link></li>
                  <li className="menu-txt"><Link to="/bracelets_everestbands" className="mobile-link" onClick={() => setShowMenu(!showMenu)}>Bracelets Everestbands</Link></li>
                  <li className="menu-txt"><Link to="/swisskubik" className="mobile-link" onClick={() => setShowMenu(!showMenu)}>Swisskubik</Link></li>
                  <li className="menu-txt"><Link to="/scatola_del_tempo" className="mobile-link" onClick={() => setShowMenu(!showMenu)}>Scatola del Tempo</Link></li>

                  {/*<li className="menu-txt"><Link className="mobile-link" to="/horlogeries">Montres & Accessoires</Link></li>*/}
                </ul>
                <li className="menu-txt"><Link className="mobile-link" to="/#contact" onClick={() => setShowMenu(!showMenu)}>Contact</Link></li>
                <li className="menu-txt"><Link className="mobile-link" to="/links" onClick={() => setShowMenu(!showMenu)}>Liens utiles</Link></li>
              </ul>
          </div>
          </div>
          : null
        }
      </div>

      <Modal
        style={{minWidth: '90vw'}}
        visible={ showModalContact }
        onOk={ handleContactFormOk }
        onCancel={ handleContactFormCancel }
        cancelButtonProps={{ style: { display: 'none' } }}
      >

        <h6>RGPD</h6>


          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block'}}>INTRODUCTION</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Ce site Internet est géré par Bijouterie Luna's SA dont le siège est en Suisse, Place des aviateurs, 4, 1228 Plan-les-Ouates à Genève. (ci-après : Bijouterie Luna's).</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous nous engageons à traiter de manière responsable vos données personnelles. Nous considérons par conséquent qu'il va de soi de respecter les exigences légales de la loi fédérale suisse sur la protection des données (LPD), de l'ordonnance relative à la loi fédérale sur la protection des données (OLPD), de la loi sur les télécommunications (LTC) et des autres dispositions de la législation suisse sur la protection des données. Nous respecterons en outre les dispositions du nouveau règlement européen sur la protection des données (RGPD) dans la mesure où celui-ci nous serait applicable.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous souhaitons ci-après vous informer de la manière dont nous traitons vos données personnelles.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>ÉTENDUE ET OBJET DE LA RÉCOLTE, DU TRAITEMENT ET DE L'UTILISATION DES DONNÉES PERSONNELLES</span>
          <span style={{fontSize: '1.2em', display: 'block', fontWeight: 'bold', marginBottom: '14px'}}>VISITE SUR NOTRE SITE INTERNET</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Pour chaque visite de nos sites Internet, nos serveurs enregistrent provisoirement chaque accès dans un fichier de protocole.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>La récolte et le traitement de ces données se font en général à titre anonyme sans référence personnelle aux fins de permettre l'utilisation du site Internet (mise en place de la liaison), de garantir durablement la sécurité et la stabilité du système et d'optimiser l'offre Internet, ainsi qu'à des fins statistiques. Les informations susmentionnées ne sont pas reliées à des données personnelles ni enregistrées avec celles-ci.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Ce n'est qu'en cas d'attaque à l'infrastructure du réseau du site de Bijouterie Luna's et toutes les suspicion d'une autre utilisation illicite ou abusive du site Internet que l'adresse IP est analysée aux fins de clarification et de défense et, le cas échéant, utilisée dans le cadre d'une procédure pénale pour l'identification et les procédures civile et pénale à l'encontre de l'utilisateur concerné.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Veuillez vous référer à la section Tracking-Tools ci-dessous pour de plus amples informations en relation avec les fichiers de protocole.</p>

          <span style={{fontSize: '1.2em', display: 'block', fontWeight: 'bold', marginBottom: '14px', marginTop: '30px'}}>EN CAS D'INSCRIPTION AUX NEWSLETTERS, D'UTILISATION DES FORMULAIRES DE CONTACT, OU D'INSCRIPTION À UN SERVICE SPÉCIFIQUE DE BIJOUTERIE LUNA'S.</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Pour recevoir la newsletter ou prendre contact avec nous au moyen d'un formulaire, la saisie véridique de certaines ou toutes les données personnelles suivantes est nécessaire:</p>

          <ul style={{padding: '20px', color: '#2c2f4c', fontSize: '1.3em'}}>
            <li>adresse e-mail</li>
            <li>civilité</li>
            <li>prénom</li>
            <li>nom</li>
            <li>adresse postale</li>
            <li>téléphone</li>
          </ul>

          <span style={{ color: '#2c2f4c', fontSize: '1.3em'}}>et plus sporadiquement:</span>

          <ul style={{padding: '20px', color: '#2c2f4c', fontSize: '1.3em'}}>
            <li>titre</li>
            <li>fonction</li>
            <li>entreprise</li>
            <li>site internet</li>
          </ul>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Ces indications nous sont nécessaires pour traiter votre requête, vous envoyer des newsletters, et/ou utiliser les données à des fins de marketing ou commerciale.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>TRANSMISSION DES DONNÉES À DES TIERS</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous ne transmettons vos données que si vous y avez expressément consenti, si la loi nous y oblige ou dans la mesure où il est nécessaire pour faire valoir nos droits. Nous transmettons également vos données à des entreprises qui nous sont liées.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous transmettons par ailleurs les données personnelles d'utilisateurs à des tiers dans la mesure où cela est nécessaire dans le cadre de l'utilisation du site Internet ainsi que pour répondre à des questions, traiter des demandes de renseignements ou fournir le cas échéant des prestations de service exigées par l'utilisateur. L'utilisation par les tiers des données transmises à cet effet est strictement limitée.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>DROIT AUX RENSEIGNEMENTS, EFFACEMENT ET CORRECTION</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Vous pouvez à tout moment demander des renseignements sur vos données personnelles que nous avons enregistrées. Les demandes de renseignements doivent être soumises par écrit avec preuve d'identité. De même, vous avez le droit d'exiger à tout moment l'effacement ou la rectification de vos données enregistrées chez nous.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Vous pouvez faire par e-mail une demande correspondante à info<span></span>@bijouterie-lunas.ch. Vous trouverez d'autres possibilités de contact sous « Contact ».</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Veuillez noter que certaines données doivent, selon la loi, être conservées pendant une durée déterminée. De telles données doivent donc rester enregistrées chez nous jusqu'à expiration de ces délais. Nous bloquons ces données dans notre système et ne les utilisons que pour satisfaire les directives légales.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>SÉCURITÉ DES DONNÉES</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous nous servons de mesures de sécurité techniques et organisationnelles appropriées nous semblant adéquates pour protéger vos données que nous avons enregistrées contre la manipulation, la perte partielle ou totale et l'accès non autorisé de tiers. Nos mesures de sécurité sont adaptées en permanence conformément à l'évolution technologique.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous prenons également très au sérieux notre propre protection des données internes à l'entreprise. Nos collaborateurs et les entreprises prestataires de services que nous avons mandatées sont tenus à la confidentialité et au respect des dispositions légales de protection des données. L'accès aux données personnelles n'est par ailleurs accordé que dans la mesure du nécessaire.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>COOKIES</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Les cookies aident, entre autres, à rendre votre visite sur le site de Bijouterie Luna's, plus simple, plus agréable et plus utile. Les cookies sont des fichiers d'information que votre navigateur Internet enregistre automatiquement sur le disque dur de votre ordinateur lorsque vous visitez notre site Internet.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Les cookies n'endommagent pas le disque dur de votre ordinateur et ne nous transmettent pas vos données personnelles.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous utilisons des cookies par exemple pour vous reconnaître en tant qu'utilisateur enregistré sans que vous ayez besoin de vous reconnecter à chaque fois. Cette utilisation ne signifie pas dans ce cas que nous recevons de nouvelles données personnelles à votre sujet en tant que visiteur en ligne.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Une demande d'acceptation des cookies se fera sur les pages de Bijouterie Luna's. Vous pouvez toutefois configurer votre navigateur de façon à ce qu'aucun cookie ne soit classé sur votre ordinateur. Vous trouverez de plus amples informations à ce sujet dans les options de paramétrage de votre navigateur.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>La désactivation des cookies peut toutefois avoir pour conséquence que vous ne puissiez utiliser toutes les fonctions de notre portail.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>TRACKING-TOOLS</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Le site Internet de Bijouterie Luna's utilisent Google Analytics, un service d'analyse de sites Internet fourni par Google Inc. (https://www.google.com). Les données collectées par le biais de cookies déposés par ce site sont collectées par Google Analytics, pour une durée de 14 mois. Google Analytics utilise des méthodes permettant une analyse de l'utilisation du site Internet , comme par exemple les «cookies» (voir ci-dessus «Cookies»). Les informations sur l'utilisation du site Internet sont en règle générale transmises à un serveur Google aux USA où elles sont enregistrées. Du fait de l'activation de l'anonymisation des IP sur ce site Internet, l'adresse IP est raccourcie dans les États membres de l'Union européenne ou dans d'autres États signataires de la Convention sur l'Espace économique européen ainsi qu'en Suisse avant la transmission aux USA. L'adresse IP complète est transmise à un serveur de Google aux USA, où elle est raccourcie dans de seuls cas exceptionnels. L'adresse IP anonymisée transmise dans le cadre de Google Analytics par le navigateur de l'utilisateur n'est pas regroupée avec d'autres données de Google.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Pour votre information, dans le cadre des données collectées selon ce qui précède, nous enregistrons les données suivantes:</p>

          <ul style={{padding: '20px', color: '#2c2f4c', fontSize: '1.3em'}}>
            <li>l'adresse IP de l'ordinateur demandeur,</li>
            <li>la date et l'heure de l'accès,</li>
            <li>le nom et l'URL des données consultées,</li>
            <li>le site Internet à partir duquel il a été accédé à notre domaine,</li>
            <li>le système d'exploitation de votre ordinateur et du navigateur que vous utilisez,</li>
            <li>le pays d'où l'accès à notre site Internet a lieu,</li>
            <li>le nom de votre fournisseur d'accès à l'Internet.</li>
          </ul>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Les informations sont utilisées pour analyser l'utilisation du site Internet, pour composer des rapports sur les activités sur les sites Internet et pour fournir d'autres prestations de service liées à l'utilisation des sites Internet aux fins d'étude de marché et d'organisation des sites Internet conformément aux besoins.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Les utilisateurs peuvent empêcher la saisie des données produites par le cookie et se rapportant à l'utilisation du site Internet par l'utilisateur concerné (y. c. l'adresse IP) à Google ainsi que le traitement de ces données par Google, en téléchargeant et en installant le browser-plugin disponible sous le lien suivant:</p>

            <a href="https://tools.google.com/dlpage/gaoptout?hl=fr">https://tools.google.com/dlpage/gaoptout?hl=fr</a>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Les utilisateurs peuvent, en alternative au browser-plugin, cliquer sur la bannière située en bas de page pour empêcher la saisie par Google Analytics sur le site Internet à l'avenir. Un opt-out-cookie est classé sur le terminal de l'utilisateur. Si les utilisateurs effacent des cookies (voir ci-dessus «Cookie»), il faudra à nouveau cliquer sur cette bannière située en bas de page.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>FONCTIONNALITÉS DES MÉDIAS SOCIAUX</span>
          <span style={{fontSize: '1.2em', display: 'block', fontWeight: 'bold', marginBottom: '14px'}}>LIENS VERS NOS PRÉSENCES DANS LES MÉDIAS SOCIAUX</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Nous avons intégré sur le site de Bijouterie Luna's des liens sur nos profils de médias sociaux sur les réseaux sociaux suivants:</p>

          <ul style={{padding: '20px', color: '#2c2f4c', fontSize: '1.3em'}}>
            <li>Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA,</li>
            <li>Instagram Inc., 1601 Willow Road, Menlo Park, California 94025, USA</li>
            <li>YouTube, un service exploité par Google Inc.</li>
          </ul>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Certains contenus (textes et photos, pseudo de l'utilisateur) qui sont publiés sur les réseaux sociaux en lien direct avec Bijouterie Luna's peuvent être repris pour les afficher sur les sites cités.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Vous êtes relié au réseau social sélectionné en cliquant sur les icônes concernés des réseaux sociaux. Vous devez pour cela toutefois vous connecter à votre compte d'utilisateur correspondant, ou déjà y être connecté.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Un lien direct entre votre navigateur et le serveur du réseau social concerné est établi si vous choisissez une des fonctionnalités mises à disposition et cliquez sur le symbole du réseau social concerné. Le réseau reçoit ainsi l'information que vous avez visité notre site Internet avec votre adresse IP et consulté le lien. Lorsque vous consultez un lien vers un réseau pendant que vous êtes connecté à votre compte dans le réseau concerné, les contenus de votre site peuvent être reliés à votre profil dans le réseau, ce qui signifie que votre réseau peut attribuer votre visite sur notre site Internet directement à votre compte utilisateur. Vous devriez, si vous souhaitez empêcher cela, vous déconnecter avant d'actionner des liens correspondants. Une attribution a lieu dans tous les cas si vous vous connectez au réseau concerné après avoir actionné le lien.</p>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>CONTACT</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Si vous avez des questions concernant la protection des données sur notre site Internet, si vous souhaitez un renseignement ou obtenir l'effacement de vos données, veuillez prendre contact en envoyant un e-mail à info<span></span>@bijouterie-lunas.ch.</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Par lettre, transmettez votre requête à l'adresse suivante:</p>

          <ul style={{padding: '20px', color: '#2c2f4c', fontSize: '1.3em'}}>
            <li>Bijouterie Luna's SA</li>
            <li>Place des aviateurs, 4</li>
            <li>1228 Plan-les-Ouates</li>
            <li>Genève, Suisse</li>
          </ul>

          <span style={{fontSize: '1.3em', fontWeight: 'bold', color: "#796b4b", display: 'block', marginTop: '30px', marginBottom: '14px'}}>CONSENTEMENTS ET AUTORISATIONS</span>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>Par l'acceptation de notre politique de protection de données, vous signifiez votre accord et nous autorisez conformément à la teneur applicable du texte ci-dessus. A des fins de clarification, votre accord et les autorisations portent en particulier sur l'un et/ou l'autre des éléments suivants (selon le contexte dans lequel les données sont traitées), étant précisé que vous pouvez les révoquer, en tout ou partie, à tout moment et pour l'avenir. Ainsi, votre consentement peut impliquer, selon le(s) cas concerné(s) que vous acceptez que Bijouterie Luna's, le cas échéant avec l'assistance de tiers :</p>

          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>traite vos données personnelles afin de fournir les prestations ou les services que vous sollicitez de Bijouterie Luna's et/ou l'une ou l'autre des entités qui lui sont liées ;</p>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>vous envoie régulièrement par e-mail des informations sur Bijouterie Luna's et/ou des invitations à des événements en rapport avec Bijouterie Luna's ;</p>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>collecte des cookies aux fins de recueillir, enregistrer et utiliser des données d'utilisation à des fins de marketing ;</p>
          <p style={{margin: 0, padding: 0, paddingTop: '10px'}}>vous propose, le cas échéant par publicité personnalisée, des manifestations en rapport avec Bijouterie Luna's, incluant notamment des invitations, enquêtes de satisfaction, offres et services particuliers, au besoin sur la base de données complémentaires à soumettre à une analyse spécifique.</p>


      </Modal>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
