import { Link } from "gatsby"
import React from "react"
import { Menu, Dropdown, Icon, Affix } from 'antd';

import logo from "../images/logo.png"
import fb_logo_h from "../images/facebook-logo-h.svg"
import fb_logo_b from "../images/facebook-logo-b.svg"
import insta_logo from "../images/instagram-logo.svg"

const menuSavoirFaireHorlogeries = (
	<Menu>
		<Menu.Item>
			<Link to="/savoirfaireHorlogeries#reparation_de_montres">Réparations de montres</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/savoirfaireHorlogeries#entretiens_et_nettoyages">Entretiens et Nettoyages</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/savoirfaireHorlogeries#changements_de_piles">Changements de piles</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/savoirfaireHorlogeries#restaurations">Restaurations</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/savoirfaireHorlogeries#changements_de_bracelets">Changements de bracelets</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/savoirfaireHorlogeries#conciergerie">Conciergerie</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/savoirfaireHorlogeries#rachat">Rachat de montres & dépôt-vente</Link>
		</Menu.Item>
	</Menu>
);

const menuSavoirFaire = (
	<Menu>
		<Menu.Item>
			<Link to="/creation">Création sur mesure</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/transformation">Transformation</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/expertises">Estimations et Expertises</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/restauration">Restauration</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/sertissage">Sertissage</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/gravures">Gravures</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/renfilage">Renfilage de Colliers</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/achat_vieil_or">Achat vieil Or</Link>
		</Menu.Item>
	</Menu>
);

const menuBijoux = (
	<Menu>
		<Menu.Item >
			<Dropdown overlay={menuSavoirFaire}><Link to="/savoirfaire">Notre savoir-faire <Icon type="down" /></Link></Dropdown>
		</Menu.Item>
		<Menu.Item>
			<Link to="/bagues">Bagues</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/alliances">Alliances</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/solitaires">Solitaires</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/colliers_pendentifs">Colliers & Pendentifs</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/boucles_d_oreilles">Boucles d'oreilles</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/perles_pierres">Perles & Pierres</Link>
		</Menu.Item>
	</Menu>
);

const marquesHorlogeries = (
	<Menu>
		<Menu.Item>
			<Link to="/horlogerie_yvan_monnet">Yvan Monnet</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/horlogerie_grovana">Grovana</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/horlogerie_maserati">Maserati</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/horlogerie_gvchiani">Gvchiani Genève</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/bracelets_everestbands">Bracelets Everestbands</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/swisskubik">Swisskubik</Link>
		</Menu.Item>
		<Menu.Item>
			<Link to="/scatola_del_tempo">Scatola del Tempo</Link>
		</Menu.Item>
	</Menu>
);

const menuHorlogeries = (
	<Menu>
		<Menu.Item>
			<Link to="/montres-neuves-et-d-occasion"> Montres neuves et d'occasion </Link>
		</Menu.Item>
		<Menu.Item>
			<Dropdown overlay={menuSavoirFaireHorlogeries}><Link to="/savoirfaireHorlogeries">Notre savoir-faire <Icon type="down" /></Link></Dropdown>
		</Menu.Item>
		<Menu.Item>
			<Dropdown overlay={marquesHorlogeries}><Link to="/horlogeries">Montres & Accessoires <Icon type="down" /></Link></Dropdown>
		</Menu.Item>
	</Menu>
);




const Header = ({ siteTitle }) => (
    <>
		<Affix>
			<header>
				<nav id="navbar">
					<h2 className="header-BJC">Bijoutier - Joaillier - Créateur</h2>
					{/*<Select defaultValue="fr" style={{ width: 120, top: 10, right: 10, position: 'absolute' }}>
						<Select.Option value="fr">Français</Select.Option>
						<Select.Option value="en">Anglais</Select.Option>
					</Select>*/}
					<ul className="menu-txt" id="ul-navbar">
						<li className="menu-txt"><Link to="/">Accueil</Link></li>
						<li className="menu-txt"><Link to="/#news">News</Link></li>
						<li className="menu-txt"><Link to="/#presentation">Histoire</Link></li>
						<li className="menu-txt"><Dropdown overlay={menuBijoux} trigger={[ 'hover' ]}><Link to="/bijoux">Bijouterie <Icon type="down" /></Link></Dropdown></li>
						<li className="menu-txt"><Dropdown overlay={menuHorlogeries} trigger={[ 'hover' ]}><Link to="/horlogerie">Horlogerie <Icon type="down" /></Link></Dropdown></li>
						<li className="menu-txt"><Link to="/#contact">Contact</Link></li>
						<li className="menu-txt"><Link to="/links">Liens utiles</Link></li>
					</ul>
				</nav>
				<div className="logo" id="logo"><Link to="/" className="logo-link" ><img src={logo} alt="Logo" className="logo-img" /></Link></div>
				<div className="social_desktop">
					<a href="https://www.facebook.com/luxurywatchesbylunas/"><img src={fb_logo_h} alt="Facebook" style={{width: '32px', margin: '10px'}}/></a>
					<a href="https://www.facebook.com/bijouterielunas/"><img src={fb_logo_b} alt="Facebook" style={{width: '32px', margin: '10px'}}/></a>
					<a href="https://www.instagram.com/lunas_jewels_watches/"><img src={insta_logo} alt="Instagram" style={{width: '32px', margin: '10px'}}/></a>
				</div>
			</header>
			<div className="border-gold" />
		</Affix>
		<div className="social_mobile">
			<a href="https://www.facebook.com/luxurywatchesbylunas/"><img src={fb_logo_h} alt="Facebook" style={{ width: "32px", margin: "10px" }}/></a>
			<a href="https://www.facebook.com/bijouterielunas/"><img src={fb_logo_b} alt="Facebook" style={{ width: "32px", margin: "10px" }}/></a>
			<a href="https://www.instagram.com/lunas_jewels_watches/"><img src={insta_logo} alt="Instagram" style={{ width: "32px", margin: "10px" }}/></a>
		</div>
    </>
)

export default Header
